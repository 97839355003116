import { useEffect, useState } from 'react';
import useCollections from '../Hooks/useCollections';
import useHelpers from '../Hooks/useHelpers';
import useTarifs from '../Hooks/useTarifs';
import styles from './CardModel.module.scss';
import DynImage from './DynImage';
import { faStars } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useTranslation from "../Hooks/useTranslation"

function CardModel(props) {
   const { getModelById, isHJOModel } = useCollections();
   const { getMinMaxPriceForModel } = useTarifs();
   const { formatPPUPrice } = useHelpers();
   const [minMax, setMinMax] = useState({ min: 0, max: 0 });
   const [model, setModel] = useState(null);
   const [addClasses, setAddClasses] = useState("")
   const { trans } = useTranslation()

   useEffect(() => {
      let newClass = ""
      if (props.inactive) {
         newClass = newClass + " " + styles.inactive
      }
      if (props.small) {
         newClass = newClass + " " + styles.small
      }
      setAddClasses(newClass)
   }, [props])

   useEffect(() => {
      const a_model = getModelById(props.id);

      setModel(a_model);
      let minmax = getMinMaxPriceForModel(a_model?.id)
      if (!minmax.reason) {
         // reason contient un texte d'erreur si pb
         setMinMax(minmax);
      } else {
         setMinMax({ min: 0, max: 0 });
      }
   }, [setMinMax, getMinMaxPriceForModel, setModel, getModelById, props.id]);

   function onClick(evt) {
      if (props.onClick) {
         props.onClick(evt);
      }
   }

   // console.log('CARD_MODEL', props, model);

   return (
      <button
         className={`${styles.card_model} ${addClasses}`}
         onClick={onClick}
      >
         {model && (
            <DynImage
               className={styles.imgmodel}
               src={[`catalogue/modeles/${model?.id}@2x.png`, model?.bymessika ? model.bymessika[0] : null]}
               alt={`Model ${model?.id}`}
            />
         )}
         {model && model.argu.includes(trans('?substr_exclu_bout')) &&
            <span className={`${styles.stars} ${addClasses}`}>
               <FontAwesomeIcon icon={faStars} />
            </span>}
         {/* <img src={props.image} alt="Model" /> */}
         <div>
            <span>
               {model?.id} - {model?.label}
            </span>
            {model && !isHJOModel(model.id) && <span>{formatPPUPrice(minMax.min)}</span>}
         </div>
      </button>
   );
}

export default CardModel;
