import useTranslation from '../Hooks/useTranslation';
import styles from './QtyPicker.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faMagnifyingGlassChart, faCheck, faRing, faGem, faTag, faMinus, faPlus, faPen, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { faRingsWedding, faCircleQuestion, faStars } from '@fortawesome/pro-solid-svg-icons';
import usePrefStore from '../stores/prefs';
import Button from '../components/Button';
import useHelpers from '../Hooks/useHelpers';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Checkbox, States as CheckboxStates } from './Checkbox';
import Keypad from './Keypad';
import useCollections from '../Hooks/useCollections';
import useTarifs from '../Hooks/useTarifs';
import DynImage from './DynImage';
import useSales from '../Hooks/useSales';
// import { useLogger } from '../Contexts/Logger';
// import useTest from "../Hooks/useTest"
import { AppWriteContext } from '../Contexts/AppWriteContext';
import glob_const from '../libs/glob_const'

function PickerButton(props) {
   const [addClasses, setAddClasses] = useState('');
   const [stockStatus, setStockStatus] = useState(styles.stock_unknown);

   function onClick(evt) {
      evt.stopPropagation();
      if (props.onClick) {
         props.onClick(props.value);
      }
   }

   useEffect(() => {
      let newClass = '';
      if (props.selected) {
         newClass = newClass + ' ' + styles.selected;
      }
      if (props.tiny) {
         newClass = newClass + ' ' + styles.tiny;
      }
      setAddClasses(newClass);

      if (props.stock === 'ok') {
         setStockStatus(styles.stock_ok);
      } else if (props.stock === 'nok') {
         setStockStatus(styles.stock_nok);
      } else if (props.stock === 'warn') {
         setStockStatus(styles.stock_warn);
      } else {
         setStockStatus(styles.stock_unknown);
      }

   }, [props]);

   return (
      <button className={`${stockStatus} ${styles.pickerbutton} ${addClasses}`} onClick={onClick}>
         {props.children}
      </button>
   );
}

function CellForMulti(props) {
   const [value, setValue] = useState(null);
   const [stockStatus, setStockStatus] = useState(styles.stock_unknown);

   useEffect(() => {
      setValue(props.value);
   }, [props]);

   function doSelect(evt) {
      evt.stopPropagation();
      // console.log("LA");
      if (props.onSelect) {
         // console.log("LA2");
         props.onSelect(value);
      }
   }

   useEffect(() => {
      if (props.stock === 'ok') {
         setStockStatus(styles.stock_ok);
      } else if (props.stock === 'nok') {
         setStockStatus(styles.stock_nok);
      } else if (props.stock === 'warn') {
         setStockStatus(styles.stock_warn);
      } else {
         setStockStatus(styles.stock_unknown);
      }
      // if (props.focus) {

      // }
   }, [props]);

   return (
      <span className={`${stockStatus} ${props.focus ? styles.focus : ''}`} onClick={doSelect}>
         {value > 0 ? value : ''}
      </span>
   );
}

const stockStatuses = {
   "inconnu": 1,
   "indispo": 2,
   "dispo": 3,
}

function QtyPicker(props) {
   const { trans } = useTranslation();
   // const { test } = useTest()
   // Prefs store
   // const globalClosePopups = usePrefStore((state) => state.globalClosePopups);
   // const showCess = usePrefStore((state) => state.showCess);
   // const debug = usePrefStore((state) => state.debug);
   // const curOrder = usePrefStore((state) => state.curOrder);
   // const qtyMaxOrder = usePrefStore((state) => state.qtyMaxOrder);
   const { globalClosePopups, showCess, debug, curOrder, qtyMaxOrder } = usePrefStore();
   // States
   const [setStockChecked] = useState(false);
   const [multiple, setMultiple] = useState(false);
   const [quantitySingle, setQuantitySingle] = useState(1);
   const [model, setModel] = useState({});
   const [curPrice, setCurPrice] = useState(null);
   const [curCessPrice, setCurCessPrice] = useState(null);
   const [availColors, setAvailColors] = useState(null); // Toutes les couleurs disponibles
   const [availSizes, setAvailSizes] = useState(null); // Toutes les tailles disponibles, toutes couleurs confondues
   const [availSizesForCurSingleColor, setAvailSizesForCurSingleColor] =
      useState(null); // Toutes les tailles disponibles pour la couleur (single) choisie
   const [allQuantitiesByColorSize, setAllQuantitiesByColorSize] = useState({}); // Mode multi : Quantité par couleur+taille. Format {couleur{taille: qté, ...}, ...}
   const [curQtyInKeyPad, setCurQtyInKeyPad] = useState(null); // Mode multi : Quantité en cours de saisie
   const [prevQtyInKeyPad, setPrevQtyInKeyPad] = useState(null); // Mode multi : Sauvegarde de l'ancienne quantité avant de se mettre en cours de saisie
   const [selectedColorInMulti, setSelectedColorInMulti] = useState(null); // Couleur choisie en mode multi ou null si pas de sélection active
   const [selectedSizeInMulti, setSelectedSizeInMulti] = useState(null); // Taille choisie en mode multi ou null si pas de sélection active
   const [curSingleColor, setCurSingleColor] = useState(null); // Couleur choisie en mode single
   const [curSingleSize, setCurSingleSize] = useState(null); // Taille choisie en mode single
   const [errorInTarif, setErrorInTarif] = useState(false); // Code si erreur pour récupérer dans le tarif
   const [showDebug, setShowDebug] = useState(false); // Faut-il montrer les infos de debug ?
   const [isKeypadVisible, setIsKeypadVisible] = useState(false); // Faut-il montrer le keypad ?
   const [isKeypadEnabled, setIsKeypadEnabled] = useState(false); // Le keypad est-il actif ? (en cours de saisie de valeur)
   const [colorItems, setColorItems] = useState(null);
   const [sizeItems, setSizeItems] = useState(null);
   const [region, setRegion] = useState(null);        // Si on affiche pour un client ou une commande pas sélectionné(e)
   const [currencyPPU, setCurrencyPPU] = useState(null);    // Si on affiche pour un client ou une commande pas sélectionné(e)
   const [currencyCES, setCurrencyCES] = useState(null);    // Si on affiche pour un client ou une commande pas sélectionné(e)
   const [stocks, setStocks] = useState(null);    // Les infos de stock récupérées du serveur
   const [stockStatus, setStockStatus] = useState();    // Les infos de stock récupérées du serveur
   const [depot, setDepot] = useState(null);    // Le dépôt sur client courant ou J01 par défaut
   const [argu, setArgu] = useState(null)
   //
   const { formatWeightWithNC, formatDiamWeightWithNC, formatPPUPriceWithNC, formatCESPriceWithNC } = useHelpers();
   const { getModelById } = useCollections();
   const { getColorsForModel, getSizesForModelColor, getMinMaxPriceForModel, getMinMaxCessPriceForModel, getAllSizesForModel } = useTarifs();
   const { getCustomerById } = useSales()
   const refKeypad = useRef(null);
   // const { log } = useLogger();
   const { findRegionFromCessionTarifNames, findRegionFromPublicTarifNames } = useTarifs()
   const { getStock } = { ...AppWriteContext() };

   // console.log("QTY", quantitySingle)
   // console.log('QTY_PICKER', props)

   useEffect(() => {
      /**
       * Structure de AllQuantitiesByColorSize
       * {
       *    WG: {
       *       47: 5,
       *       ...
       *    }
       *    ...
       * }
       */
      const res = props?.multi?.reduce((prev, val) => {
         const color = val.color
         const size = val.size
         const qty = val.qty

         // console.log(`Color: ${color}, Size: ${size}, Qty: ${qty}`)
         if (color && prev[color]) {
            if (size && prev[color][size]) {
               console.error(`QtyPicker - On a déjà une ligne pour ${model}-${color}-${size} !`)
               return { ...prev }
            } else {
               // console.log('ICI1');
               return { ...prev, [color]: { ...prev[color], [size]: qty } }
            }
         } else {
            // console.log('ICI2');
            return { ...prev, [color]: { [size]: qty } }
         }
      }, {})
      setAllQuantitiesByColorSize(res ?? {})
      // console.log('setAllQuantitiesByColorSize', res ?? {})
      if (props?.color) {
         setCurSingleColor(props.color)
      }
      if (props?.size) {
         setCurSingleSize(props.size)
      }
      if (props?.qty) {
         setQuantitySingle(props.qty)
      }
   }, [model, props, trans])

   useEffect(() => {
      // console.log('::PROPS', props);
      if (props.customer) {
         const a_customer = getCustomerById(props.customer)
         setDepot(a_customer?.depot)
         console.log('::A_CUST', a_customer);
         // const region_name = findRegionFromTarifNames(a_customer?.tarifs.ppu.tarif, a_customer?.tarifs.ppu.currency)
         let region_name = findRegionFromCessionTarifNames(a_customer?.tarifs.ces.tarif, a_customer?.tarifs.ces.currency)
         if (!region_name) {
            // !!!!! NB : OUI, c'est bien CES qui est pris dans a_customer pour chercher la région. C'est pour les clients qui n'ont pas de tarif cession mais un tarif public remisé
            region_name = findRegionFromPublicTarifNames(a_customer?.tarifs.ces.tarif, a_customer?.tarifs.ces.currency)
         }
         console.log('::REGION', region_name);
         setCurrencyPPU(a_customer?.tarifs.ppu.currency)
         setCurrencyCES(a_customer?.tarifs.ces.currency)
         console.log('::CURRENCY_CES', currencyCES);
         console.log('::CURRENCY_PPU', currencyPPU);

         setRegion(region_name)
      }
   }, [currencyCES, currencyPPU, findRegionFromCessionTarifNames, findRegionFromPublicTarifNames, getCustomerById, props])

   // console.log('>>>PROPS', props);

   useEffect(() => {
      const allColors = getColorsForModel(props.model);
      console.log('getColorsForModel', props.model, allColors, (allColors.colors?.length > 0 ? allColors.colors[0] : null))

      const the_model = getModelById(props.model)
      setModel(the_model);
      if (the_model?.argu.includes(trans('?substr_exclu_bout'))) {
         // console.log('+++MODEL3');
         // setArgu(trans('?Exclu boutique'))
         setArgu(<FontAwesomeIcon icon={faStars} />)
      } else {
         // console.log('+++MODEL4');
         setArgu(null)
      }

      setAvailColors(allColors.colors);
      if (!props.color) {  // Couleur pas donnée par l'appelant (on vient surement du catalogue)
         setCurSingleColor(allColors.colors?.length > 0 ? allColors.colors[0] : null); // Trouver une autre façon que de prendre par défaut le 1er de la liste ?
      }
   }, [props, getModelById, getColorsForModel, trans]);

   useEffect(() => {
      const arr = getAllSizesForModel(props.model).sizes
      // console.log('getAllSizesForModel(props.model).sizes', arr)
      if (arr?.length) {
         setAvailSizes(arr);
      } else {
         setAvailSizes(['']);
      }
   }, [getAllSizesForModel, props]);

   // useEffect(() => {
   //    console.log('**CHG getAllSizesForModel');
   // }, [getAllSizesForModel])

   // useEffect(() => {
   //    console.log('**CHG getAllSizesForModel', getAllSizesForModel);
   // }, [getAllSizesForModel])

   // useEffect(() => {
   //    console.log('**CHG props.model', props.model);
   // }, [props.model])

   // useEffect(() => {
   //    console.log('**CHG props.onHide', props.onHide);
   // }, [props.onHide])

   // useEffect(() => {
   //    console.log('**CHG props.onAdd', props.onAdd);
   // }, [props.onAdd])

   useEffect(() => {
      const allSizes = getSizesForModelColor(props.model, curSingleColor);

      if (!allSizes.sizes && allSizes.reason) {
         // error("getSizesForModelColor", allSizes.reason)
         setErrorInTarif(
            `Error ${allSizes.reason.code} - ${allSizes.reason.id} - ${allSizes.reason.text}`
         );
      } else {
         setErrorInTarif(null);
      }
      // console.log('getSizesForModelColor', props.model, curSingleColor)
      setAvailSizesForCurSingleColor(allSizes.sizes);
      setCurSingleSize(cur_size => {
         if (!allSizes.sizes?.includes(cur_size)) {
            return allSizes.sizes?.length > 0 ? allSizes.sizes[0] : null;
         } else {
            return cur_size
         }
      })
      // console.log('getMinMaxPriceForModel', props.model, getMinMaxPriceForModel(props.model), getMinMaxPriceForModel(props.model)?.min)
      const min_max = getMinMaxPriceForModel(props.model, region)
      setCurPrice(min_max?.min);

      const min_max_cess = getMinMaxCessPriceForModel(props.model, region)
      // console.log('::getMinMaxCessPriceForModel', props.model, region, min_max_cess);
      setCurCessPrice(min_max_cess?.min);
   }, [curSingleColor, getMinMaxPriceForModel, getMinMaxCessPriceForModel, getSizesForModelColor, props, region]);

   // console.log('PICK_MODEL', model, props, curPrice)

   function onClickBackground() {
      console.log('onClickBackground');
      if (props.onHide) {
         if (globalClosePopups !== 'button') {
            props.onHide();
         }
      }
   }

   function doNothing(evt) {
      console.log('doNothing');
      evt.stopPropagation();
      if (selectedColorInMulti !== null) {
         cancelKeypad();
      } else if (isKeypadVisible) {
         // On est en mode mono, keypad affiché
         cancelKeypad();
      }
   }

   function hidePopup() {
      if (props.onHide) {
         props.onHide();
      }
   }

   function doCancel() {
      hidePopup();
   }

   function doAddOrUpdate() {
      const func = props.onAdd ?? props.onUpdate
      // La différence entre Add et Update ne sert juste qu'à distinguer comment on doit afficher le texte dans le bouton : "Ajouter" ou "Modifier", sinon les 2 fonctions ont le même type de code dans le propriétaire du <QtyPicker... Cependant il n'en faut qu'un seul et Add est prioritaire

      if (func) {
         if (multiple) {
            func(props.model, true, Object.keys(allQuantitiesByColorSize).reduce((prev, color) => {
               const item = allQuantitiesByColorSize[color]
               const subs = Object.keys(item).reduce((prev, size) => {
                  const qty = item[size]
                  return [...prev, { color, size: size === "null" ? "" : size, qty }]
               }, [])
               return [...prev, ...subs]
            }, []))
         } else {
            func(props.model, false, [{ color: curSingleColor, size: curSingleSize, qty: quantitySingle }])
         }
      }
      hidePopup();
   }

   async function checkAvail() {
      const data = await getStock(props.model, depot)

      if (data.status) {
         const json = JSON.parse(data.data)
         setStocks(json)
         setStockStatus((Object.keys(json).length <= 0) ? stockStatuses.indispo : stockStatuses.dispo)
      } else {
         setStocks(null)
         setStockStatus(stockStatuses.inconnu)
      }
      setStockChecked(true)
   }

   function changeMultiple(status) {
      setMultiple(status === CheckboxStates.Checked);
      // console.log('LA1');
      cancelKeypad();
      // return true; // Chnagement tjs OK
   }

   function addQuantity(val) {
      let newVal = quantitySingle + val;

      // evt.stopPropagation()
      if (newVal <= 0) {
         newVal = 1;
      } else if (newVal > qtyMaxOrder) {
         newVal = qtyMaxOrder
      }
      setQuantitySingle(newVal);
      focusKeypad();
   }

   function validateMultiQuantity(val) {
      // console.log("VALID", val)
      closeKeypad();
   }

   function getQtyForColorSize(color, size) {
      if (allQuantitiesByColorSize && allQuantitiesByColorSize[color]) {
         return allQuantitiesByColorSize[color][size === "" ? null : size] ?? 0;
         // return allQuantitiesByColorSize[color][size] ?? 0;
      } else {
         return 0;
      }
   }

   function setQtyForColorSize(color, size, value) {
      setAllQuantitiesByColorSize({
         ...allQuantitiesByColorSize,
         [color]: { ...allQuantitiesByColorSize[color], [size === "" ? null : size]: value }
      });
   }

   function cancelKeypad() {
      if (isKeypadVisible) {
         if (selectedColorInMulti === null) {
            // On est en mode mono
            console.log('ICI2');
            setQuantitySingle(prevQtyInKeyPad); // On remet la valeur précédente
         } else {
            setQtyForColorSize(
               selectedColorInMulti,
               selectedSizeInMulti,
               prevQtyInKeyPad
            ); // On remet la valeur précédente
         }
      }
      setCurQtyInKeyPad(null);
      closeKeypad();
   }

   function closeKeypad() {
      setSelectedColorInMulti(null);
      setSelectedSizeInMulti(null);
      setIsKeypadVisible(false);
      setIsKeypadEnabled(false);
   }

   // force : permet de ne pas tester la visibilité du keypad quand on est sûr et parce que le useState() ne sera pas forcément le reflet de son état réel au moment de l'appel de focusKeypad !
   const focusKeypad = useCallback(
      (force = false) => {
         if (force || (isKeypadVisible && isKeypadEnabled)) {
            refKeypad.current.focus();
         }
      },
      [isKeypadEnabled, isKeypadVisible]
   );

   function activateKeypadInMultiMode(color, size) {
      setPrevQtyInKeyPad(getQtyForColorSize(color, size));
      setSelectedColorInMulti(color);
      setSelectedSizeInMulti(size);
      setCurQtyInKeyPad(0);
      // console.log('RefKeyPad', refKeypad)
      setIsKeypadVisible(true);
      setIsKeypadEnabled(true);
      focusKeypad(true);
   }

   function activateKeypadInMonoMode() {
      setPrevQtyInKeyPad(quantitySingle);
      setSelectedColorInMulti(null); // Par sécurité
      setSelectedSizeInMulti(null); // Par sécurité
      setCurQtyInKeyPad(0);
      // refKeypad.current.focus()
      setIsKeypadVisible(true);
      setIsKeypadEnabled(true);
      focusKeypad(true);
   }

   function doSelectCellForMulti(color, size) {
      if (selectedColorInMulti === color && selectedSizeInMulti === size) {
         cancelKeypad();
      } else {
         activateKeypadInMultiMode(color, size);
      }
   }

   const selectColor = useCallback((color) => {
      setCurSingleColor(color);
      focusKeypad();
   },
      [focusKeypad]
   );

   const selectSize = useCallback((size) => {
      setCurSingleSize(size);
      focusKeypad();
   },
      [focusKeypad]
   );

   function toggleShowDebug() {
      setShowDebug(!showDebug);
   }

   function onKeypadChange(val) {
      console.log('--->');
      setCurQtyInKeyPad(val);
      if (selectedColorInMulti !== null) {
         setQtyForColorSize(selectedColorInMulti, selectedSizeInMulti, val);
      } else {
         console.log('ICI3');
         setQuantitySingle(val);
      }
   }

   // Affiche le keypad en mode mono (l'utilisateur a cliqué sur le champ quantité)
   function toggleShowKeypad() {
      if (!isKeypadVisible) {
         activateKeypadInMonoMode();
      } else {
         closeKeypad();
      }
   }

   useEffect(() => {
      let keys

      if (stocks) {
         keys = Object.keys(stocks)
         console.log('Keys', keys);
      }

      setColorItems(
         availColors?.map((item) => {
            let stock_level = null, stock_value
            const cur_key = `${props.model}-${item}`
            // const stock_value = stocks ? stocks[cur_key] : null

            console.log('KEYS', keys);
            if (keys) {
               stock_value = keys.reduce((prev, a_key) => {
                  console.log(a_key, cur_key);
                  if (a_key.includes(cur_key)) {
                     return prev + stocks[a_key]
                  } else {
                     return prev
                  }
               }, 0)
               console.log('QTE', stock_value);
               if (stock_value > glob_const.stock_levels.WARN) {
                  stock_level = 'ok'
               } else if (stock_value > glob_const.stock_levels.NOK) {
                  stock_level = 'warn'
               } else if (stock_value !== null) {
                  stock_level = 'nok'
               }
            }

            if (item === curSingleColor) {
               return (
                  <PickerButton key={`color_${item}`} value={item} selected stock={stock_level}>
                     {item}
                  </PickerButton>
               );
            } else {
               return (
                  <PickerButton key={`color_${item}`} value={item} stock={stock_level} onClick={selectColor}>
                     {item}
                  </PickerButton>
               );
            }
         })
      );
   }, [availColors, curSingleColor, props, selectColor, stocks]);

   useEffect(() => {
      // console.log('Stocks=', stocks);
      setSizeItems(
         availSizesForCurSingleColor?.map((item) => {
            let stock_level = null

            if (stocks) {
               let stock_value
               const cur_key = `${props.model}-${curSingleColor}-${item}`

               stock_value = stocks[cur_key]
               if (stock_value > glob_const.stock_levels.WARN) {
                  stock_level = 'ok'
               } else if (stock_value > glob_const.stock_levels.NOK) {
                  stock_level = 'warn'
               } else if (stock_value !== null) {
                  stock_level = 'nok'
               }
            }

            if (item === curSingleSize) {
               return (
                  <PickerButton key={`size_${item}`} value={item} selected stock={stock_level}>
                     {item}
                  </PickerButton>
               );
            } else {
               return (
                  <PickerButton key={`size_${item}`} value={item} stock={stock_level} onClick={selectSize}>
                     {item}
                  </PickerButton>
               );
            }
         })
      );
   }, [availSizesForCurSingleColor, curSingleSize, curSingleColor, selectSize, stocks, props]);

   // useEffect(() => {
   //    console.log("***qtyByColorSize***")
   // }, [qtyByColorSize])

   // console.log('AVAIL COLORS', availColors, curSingleColor)
   // console.log('AVAIL SIZES', availSizesForCurSingleColor, curSingleSize)
   // console.log('qtyByColorSize', qtyByColorSize)

   useEffect(() => {
      if (isKeypadVisible && isKeypadEnabled) {
         focusKeypad(true)
      }
   }, [isKeypadVisible, isKeypadEnabled, focusKeypad])

   // console.log(props.qty, curOrder);

   // TODO : Virer le background de ce QtyPicker qui fait doublon avec le DarkLayer du Catalog
   return (
      <div className={styles.background} onClick={onClickBackground}>
         <div className={styles.qty_picker} onClick={doNothing}>
            <div className={styles.top}>
               <FontAwesomeIcon icon={faRingsWedding} />
               <span>{trans('?Sélecteur de quantité')}</span>
               {errorInTarif && (
                  <div>
                     <FontAwesomeIcon icon={faTriangleExclamation} onClick={toggleShowDebug} />
                     {debug && showDebug && <span>{errorInTarif}</span>}
                  </div>
               )}
               <button onClick={hidePopup}>
                  <FontAwesomeIcon icon={faXmark} />
               </button>
            </div>
            <div className={styles.header}>
               <span className={styles.modelid}>{model.id}</span>
               <span className={styles.label}>
                  {model.label}
                  <span className={styles.argu}>{argu}</span>
               </span>
               {!stockStatus && (
                  <Button small secondary red lefticon={
                     <FontAwesomeIcon icon={faMagnifyingGlassChart} />
                  } onClick={checkAvail}>
                     {trans('?Vérifier disponibilité')}
                  </Button>
               )}
               {(stockStatus === stockStatuses.dispo) && (
                  <Button small green lefticon={<FontAwesomeIcon icon={faCheck} />}>
                     {trans('?Disponible')}
                  </Button>
               )}
               {(stockStatus === stockStatuses.indispo) && (
                  <Button small red lefticon={<FontAwesomeIcon icon={faXmark} />}>
                     {trans('?Indisponible')}
                  </Button>
               )}
               {(stockStatus === stockStatuses.inconnu) && (
                  <Button small secondary red lefticon={<FontAwesomeIcon icon={faCircleQuestion} />}>
                     {trans('?Non communiqué')}
                  </Button>
               )}
               <div className={styles.info}>
                  <span className={styles.picto}>
                     <FontAwesomeIcon icon={faRing} />
                  </span>
                  <span className={styles.text}>{formatWeightWithNC(model.pds)}</span>
                  <span className={styles.picto}>
                     <FontAwesomeIcon icon={faGem} />
                  </span>
                  <span className={styles.text}>{formatDiamWeightWithNC(model.pdsdts)}</span>
                  <span className={styles.picto}>
                     <FontAwesomeIcon icon={faTag} />
                  </span>
                  <span className={styles.text}>{formatPPUPriceWithNC(curPrice, currencyPPU)}</span>
                  {showCess && <span className={styles.text}>{trans('?Cess.')} : {formatCESPriceWithNC(curCessPrice, currencyCES)}</span>}
               </div>
            </div>
            <div className={styles.detail}>
               <div className={styles.detail_header}>
                  <Checkbox caption={trans('?Saisie multiple')} checked={`${multiple ? CheckboxStates.Checked : CheckboxStates.Unchecked}`} onChange={changeMultiple} />
               </div>
               <div className={styles.leftside}>
                  <div className={styles.flipcard}>
                     <div className={styles.flipcard_inner}>
                        <div className={`${styles.flipcard_front} ${!isKeypadVisible ? styles.selected : ''}`}>
                           <DynImage
                              className={styles.imgmodel}
                              src={[`catalogue/modeles/${model.id}@2x.png`, model.bymessika ? model.bymessika[0] : null]}
                              alt={`Model ${model.id}`}
                           />
                           {/* <img src={`catalogue/modeles/${model.id}@2x.png`} alt={`Model ${model.id}`} /> */}
                        </div>
                        <div className={`${styles.flipcard_back} ${isKeypadVisible ? styles.selected : ''}`}>
                           <Keypad ref={refKeypad} onValidate={validateMultiQuantity} visible={false} disabled={!isKeypadEnabled} value={curQtyInKeyPad} min={0} max={qtyMaxOrder} onChange={onKeypadChange} onCancel={cancelKeypad}></Keypad>
                        </div>
                     </div>
                  </div>
               </div>
               <div className={styles.rightside}>
                  {!multiple && (
                     <div className={styles.boxsingle}>
                        <div className={styles.colorblock}>
                           <span>{trans('?Couleur')}</span>
                           <div>{colorItems}</div>
                        </div>
                        <div className={styles.sizeblock}>
                           <span>{trans('?Taille')}</span>
                           <div>{sizeItems}</div>
                        </div>
                        <div className={styles.quantityblock}>
                           <span>{trans('?Quantité')}</span>
                           <div>
                              <PickerButton tiny selected onClick={() => {
                                 addQuantity(-1);
                              }}>
                                 <FontAwesomeIcon icon={faMinus} />
                              </PickerButton>
                              <span onClick={toggleShowKeypad} className={`${isKeypadEnabled ? styles.selected : ''}`}>
                                 {quantitySingle}
                              </span>
                              <PickerButton tiny selected onClick={() => {
                                 addQuantity(1);
                              }}>
                                 <FontAwesomeIcon icon={faPlus} />
                              </PickerButton>
                           </div>
                        </div>
                     </div>
                  )}
                  {multiple && (
                     <div className={styles.boxmulti}>
                        <div className={styles.grid_quantities}>
                           <div className={styles.multi_header}>
                              <span></span>
                              {availColors &&
                                 availColors.map((col) => (
                                    <span key={`multicolor-${col}`}>{col}</span>
                                 ))}
                           </div>
                           <div className={styles.multi_lines}>
                              {availSizes &&
                                 availSizes.map((size) => {
                                    // console.log("***availSizes***", size)
                                    return (
                                       <div
                                          key={`multiline-line-${size}`}
                                          className={styles.multi_line}
                                       >
                                          <span>{size}</span>
                                          {availColors &&
                                             availColors.map((col) => {
                                                // console.log("***availColors***", col)
                                                let stock_level = null
                                                const stock_value = stocks ? stocks[`${props.model}-${col}-${size}`] : null

                                                // console.log(`${props.model}-${col}-${size}`, stock_value, stocks);

                                                if (stock_value > glob_const.stock_levels.WARN) {
                                                   stock_level = 'ok'
                                                } else if (stock_value > glob_const.stock_levels.NOK) {
                                                   stock_level = 'warn'
                                                } else if (stock_value !== null) {
                                                   stock_level = 'nok'
                                                }
                                                return (
                                                   // <span key={`multicolor-${col}`}>{col}</span>
                                                   <CellForMulti
                                                      key={`multiline-cell-${size}-${col}`}
                                                      stock={stock_level}
                                                      onSelect={() =>
                                                         doSelectCellForMulti(
                                                            col,
                                                            size
                                                         )
                                                      }
                                                      focus={selectedColorInMulti === col && selectedSizeInMulti === size}
                                                      value={getQtyForColorSize(col, size) ?? ''}
                                                   ></CellForMulti>
                                                );
                                             })}
                                          {/* <span className={styles.stock_ok} onClick={selectMultiCell}>0</span> */}
                                          {/* <CellForMulti onSelect={doSelectCellForMulti} stock="nok" value="0"></CellForMulti>
                                          <CellForMulti onSelect={doSelectCellForMulti} stock="nok" value="10"></CellForMulti>
                                          <CellForMulti onSelect={doSelectCellForMulti} stock="warn" value="99"></CellForMulti>
                                          <CellForMulti onSelect={doSelectCellForMulti} stock="warn" value="0"></CellForMulti>
                                          <CellForMulti onSelect={doSelectCellForMulti} stock="nok" value="0"></CellForMulti> */}
                                       </div>
                                    );
                                 })}
                           </div>
                        </div>
                     </div>
                  )}
               </div>
            </div>
            <div className={styles.footer}>
               <Button secondary onClick={doCancel}>
                  {trans('?Annuler')}
               </Button>
               {((curOrder === null) && !props.qty) &&
                  <Button disabled>
                     {trans('?Aucune commande sélectionnée')}
                  </Button>
               }
               {((curOrder !== null) || props.qty) &&
                  <Button lefticon={<FontAwesomeIcon icon={props.onAdd ? faPlus : faPen} />} onClick={doAddOrUpdate}>
                     {trans(props.onAdd ? '?Ajouter à la commande' : '?Modifier la commande')}
                  </Button>
               }
            </div>
         </div>
      </div>
   );
}

export default QtyPicker;
