import { Fragment, useCallback, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBagShopping/*, faGear*/, faAngleRight, faFileLines as faFileLinesSolid } from '@fortawesome/free-solid-svg-icons'
import { faBookmark } from '@fortawesome/pro-regular-svg-icons'
import { faUpToLine, faCloud, faCloudSlash, faHandHoldingBox, faFilePen, faShelves, faGem, faCloudXmark, faTrashXmark, faSignature } from '@fortawesome/pro-solid-svg-icons'
import { faBadgeCheck, faFilePdf, faClone, faCloudArrowUp, faPenToSquare, faEye, faTrashCan } from '@fortawesome/pro-light-svg-icons'
import styles_Searchbar from '../components/Searchbar.module.scss';
import DropdownMenu from '../components/DropdownMenu'
import Menu from '../components/Menu/Menu'
import Searchbar from '../components/Searchbar'
import usePrefStore from '../stores/prefs'
import styles from './Orders.module.scss'
import useHelpers from '../Hooks/useHelpers'
import { Checkbox, States as CheckboxStates } from '../components/Checkbox'
import PagesButtons from '../components/PagesButtons'
import useTranslation from '../Hooks/useTranslation'
import { useNavigate } from 'react-router-dom'
import DropdownField from '../components/DropdownField'
import Button from '../components/Button'
import useSales from '../Hooks/useSales'
// import { useLogger } from '../Contexts/Logger'
// import { useState } from "react"
import { useLiveQuery } from "dexie-react-hooks"
import { AppWriteContext } from '../Contexts/AppWriteContext'
import Confirm from '../components/Confirm'
import { ToastContainer } from 'react-toastify'
import MailAddressPicker from '../components/MailAddressPicker';
import { GlobalContext } from '../Contexts/GlobalContext';

const tabs = {
   sync: 'sync',
   unsync: 'unsync'
}

const global_menu_items = {
   doSync: "sync",
}

const menu_items = {
   doEdit: "edit",
   setActive: "active",
   sendPdf: "sendpdf",
   doDelete: "delete",
   doClone: "duplicate",
   doCloneToDefaultCustomer: "duplicate_to_defcust",
   doSync: "sync",
   doSee: "see",
}

const search_type = {
   in_orders: "in_orders",
   sku: "sku",
}

function Orders() {
   const listPageLength = Number(usePrefStore((state) => state.listPageLength))
   // const setListPageLength = usePrefStore((state) => state.setListPageLength)
   // const curOrder = usePrefStore((state) => state.curOrder);
   const { setListPageLength, curOrder, curCustomer, setCurOrder } = usePrefStore();
   const { setIsDarkLayerVisible } = { ...GlobalContext() }
   //
   // const [isSearchFilterVisible, setIsSearchFilterVisible] = useState(false)
   const [searchText, setSearchText] = useState('')
   const [searchBar, setSearchBar] = useState(null)
   const [countFailedSyncOrders, setCountFailedSyncOrders] = useState(0)
   const [allSyncOrders, setAllSyncOrders] = useState(null)   // Toutes les commandes, triées
   const [allUnsyncOrders, setAllUnsyncOrders] = useState(null)   // Toutes les commandes, triées
   const [filteredUnsyncOrders, setFilteredUnsyncOrders] = useState(null)
   const [filteredSyncOrders, setFilteredSyncOrders] = useState(null)
   const [curPageUnsyncOrders, setCurPageUnsyncOrders] = useState(null) // Commandes (unsync) affichées sur la page courante
   const [curPageSyncOrders, setCurPageSyncOrders] = useState(null) // Commandes (sync) affichées sur la page courante
   const [pagesButtonsSync, setPagesButtonsSync] = useState(null)
   const [pagesButtonsUnsync, setPagesButtonsUnsync] = useState(null)
   const [unsyncLines, setUnsyncLines] = useState(null)
   const [syncLines, setSyncLines] = useState(null)
   const [unsyncLinesChecked, setUnsyncLinesChecked] = useState(new Set())
   // const [syncLinesChecked, setSyncLinesChecked] = useState(new Set())
   const [unsyncLinesAllChecked, setUnsyncLinesAllChecked] = useState(false)
   // const [syncLinesAllChecked, setSyncLinesAllChecked] = useState(false)
   const [unsyncLinesNoneChecked, setUnsyncLinesNoneChecked] = useState(false)
   // const [syncLinesNoneChecked, setSyncLinesNoneChecked] = useState(false)
   const [curOrderType, setCurOrderType] = useState(tabs.unsync)
   const [curPageSync, setCurPageSync] = useState(1)
   const [nbPagesSync, setNbPagesSync] = useState(1)
   const [firstPageSync, setFirstPageSync] = useState(1)
   const [curPageUnsync, setCurPageUnsync] = useState(1)
   const [nbPagesUnsync, setNbPagesUnsync] = useState(1)
   const [firstPageUnsync, setFirstPageUnsync] = useState(1)
   const [dropdownMenu, setDropdownMenu] = useState(null)
   const [showDropdownMenuOnOrderId, setShowDropdownMenuOnOrderId] = useState(null)
   const [deleteOrderId, setDeleteOrderId] = useState(null)
   const [isItemsPerPageOpen, setIsItemsPerPageOpen] = useState(false)
   const [selectedOrder, setSelectedOrder] = useState()
   const [isConfirmDeleteVisible, setIsConfirmDeleteVisible] = useState(false)
   const [isConfirmSyncVisible, setIsConfirmSyncVisible] = useState(false)
   const [isMailAddressPickerVisible, setIsMailAddressPickerVisible] = useState(false)
   const [searchMode, setSearchMode] = useState(search_type.in_orders)
   const [filterCDE, setFilterCDE] = useState(false)
   const [filterCDI, setFilterCDI] = useState(false)
   const [filterCON, setFilterCON] = useState(false)
   const [filterOME, setFilterOME] = useState(false)
   const [lastPickedOrderId, setLastPickedOrderId] = useState(null)  // Pour envoi PDF
   const [lastPickedOrderCustId, setLastPickedOrderCustId] = useState(null)  // Pour envoi PDF
   //
   const { formatCESPrice } = useHelpers()
   const { trans, lowtrans } = useTranslation()
   const navigate = useNavigate()
   const { order_status, setOrderIdAsCurrent, getOrderById, getCustomerById, getUnsyncOrdersAsArray, getSyncOrdersAsArray, calcTotalForOrder, updateRecentOrders, updateOrder, type_order, cloneOrder, deleteOrder, canSyncOrder } = useSales()
   // const { log, error } = useLogger()
   //
   const live_unsync_orders = useLiveQuery(getUnsyncOrdersAsArray)
   const live_sync_orders = useLiveQuery(getSyncOrdersAsArray)
   // const test_orders = useLiveQuery(() => db.orders_synced.toArray())
   const { awPushWaitingOrders, awPushPDF } = { ...AppWriteContext() };
   const [showDropdownMenuGlobal, setShowDropdownMenuGlobal] = useState(false);
   const [dropdownMenuGlobal, setDropdownMenuGlobal] = useState(null);
   const [default_customer, setDefaultCustomer] = useState();

   // console.log(null.map(a => a)) // Force plantage pour tester ErrorBoundary

   useEffect(() => {
      if (curCustomer) {
         setDefaultCustomer(getCustomerById(curCustomer))
      } else {
         setDefaultCustomer(null)
      }
   }, [curCustomer, getCustomerById])

   const sortOrders = (a, b) => {
      let comp = 0
      if ((typeof a.date === 'string') && (typeof b.date === 'string')) {
         comp = b.date.localeCompare(a.date)
      }
      if (comp === 0) {
         comp = b.id.localeCompare(a.id)
      }
      return comp
   }

   useEffect(() => {
      setAllSyncOrders(live_sync_orders?.sort(sortOrders))
   }, [live_sync_orders])

   useEffect(() => {
      setAllUnsyncOrders(live_unsync_orders?.sort(sortOrders))
   }, [live_unsync_orders])

   const prepShowDropDown = useCallback(async (id) => {
      if (id !== null) {
         setShowDropdownMenuOnOrderId(id)
         setLastPickedOrderId(id)
         const order = await getOrderById(id)
         setSelectedOrder(order)
         setLastPickedOrderCustId(order.customer_id)
      } else {
         setShowDropdownMenuOnOrderId(null)
         setSelectedOrder(null)
      }
   }, [getOrderById])

   const onSearchChange = useCallback((text) => {
      setSearchText(text.trim());
   }, [setSearchText])

   const onSelect = useCallback((id) => {
      // console.log('ID>>', id, showDropdownMenuOnCustomerId);
      prepShowDropDown(null)
      if (id === menu_items.setActive) {
         setOrderIdAsCurrent(showDropdownMenuOnOrderId)
      } else if ((id === menu_items.doEdit) || (id === menu_items.doSee)) {
         setCurOrder(showDropdownMenuOnOrderId)
         updateRecentOrders(showDropdownMenuOnOrderId)
         navigate(`/order-detail?id=${showDropdownMenuOnOrderId}`, { replace: false })
      } else if (id === menu_items.doClone) {
         cloneOrder(showDropdownMenuOnOrderId)
         setCurOrderType(tabs.unsync)
      } else if (id === menu_items.doCloneToDefaultCustomer) {
         cloneOrder(showDropdownMenuOnOrderId, default_customer.id)
         setCurOrderType(tabs.unsync)
      } else if (id === menu_items.sendPdf) {
         setIsMailAddressPickerVisible(true)
      } else if (id === menu_items.doDelete) {
         setDeleteOrderId(showDropdownMenuOnOrderId)
         setIsConfirmDeleteVisible(true)
      } else if (id === menu_items.doSync) {
         updateOrder(showDropdownMenuOnOrderId, {
            status: order_status.locked4sync,   // On va essayer de la pousser sur AppWrite (c'est updateOrder qui s'en charge, elle va ensuite passer en locked)
            readonly: true,
         })
      }
   }, [prepShowDropDown, setOrderIdAsCurrent, showDropdownMenuOnOrderId, setCurOrder, updateRecentOrders, navigate, cloneOrder, default_customer, updateOrder, order_status.locked4sync])

   async function onConfirmDeleteOK() {
      setIsConfirmDeleteVisible(false)
      deleteOrder(deleteOrderId)
      setDeleteOrderId(null)
   }

   function onConfirmDeleteCancel() {
      setIsConfirmDeleteVisible(false)
      setDeleteOrderId(null)
   }

   async function onConfirmSyncOK() {
      setIsConfirmSyncVisible(false)
      // setOrderIdAsCurrent(live_order?.id)
      for (const an_order of allUnsyncOrders) {
         if (unsyncLinesChecked.has(an_order.id) && canSyncOrder(an_order)) {
            await updateOrder(an_order.id, {
               status: order_status.locked4sync,   // On va essayer de la pousser sur AppWrite (c'est updateOrder qui s'en charge, elle va ensuite passer en locked)
               readonly: true,
            })

         }
      }
   }

   function onConfirmSyncCancel() {
      setIsConfirmSyncVisible(false)
   }

   const closeAllDropDowns = useCallback(() => {
      let ret = false

      if (showDropdownMenuGlobal) {
         setShowDropdownMenuGlobal(false)
         ret = true
      }
      if (showDropdownMenuOnOrderId) {
         setShowDropdownMenuOnOrderId(null)
         ret = true
      }
      return ret
   }, [showDropdownMenuGlobal, showDropdownMenuOnOrderId])

   const onSelAllChange = useCallback((state) => {
      closeAllDropDowns()
      if (allUnsyncOrders && state) {
         setUnsyncLinesChecked(new Set(allUnsyncOrders.map((an_order) => an_order.id)))
      } else {
         setUnsyncLinesChecked(new Set())
      }
   }, [allUnsyncOrders, closeAllDropDowns])

   useEffect(() => {
      if (allUnsyncOrders) {
         setUnsyncLinesAllChecked(allUnsyncOrders.length === unsyncLinesChecked.size)
         setUnsyncLinesNoneChecked(unsyncLinesChecked.size === 0)
      } else {
         setUnsyncLinesAllChecked(false)
         setUnsyncLinesNoneChecked(true)
      }
      // if (allSyncOrders) {
      //    setSyncLinesAllChecked(allSyncOrders.length === syncLinesChecked.size)
      //    setSyncLinesNoneChecked(syncLinesChecked.size === 0)
      // } else {
      //    setSyncLinesAllChecked(false)
      //    setSyncLinesNoneChecked(true)
      // }
   }, [allUnsyncOrders, unsyncLinesChecked.size])

   const onShowMenu = useCallback(
      (evt, id) => {
         evt.stopPropagation()
         if (showDropdownMenuOnOrderId === id) {
            prepShowDropDown(null)
         } else {
            prepShowDropDown(id)
         }
      },
      [prepShowDropDown, showDropdownMenuOnOrderId]
   )

   const onChangeChoice = useCallback((id, state, type) => {
      // console.log(
      //    `Item ${id}${state === CheckboxStates.Checked ? 'Checked' : 'Unchecked'
      //    }`
      // )
      if (type === order_status.new) {
         setUnsyncLinesChecked(cur => {
            if (state === CheckboxStates.Checked) {
               return new Set([...cur, id])
            } else {
               return new Set([...cur].filter(an_id => an_id !== id))
            }
         })
      }
      // else {
      //    setSyncLinesChecked(cur => {
      //       if (state === CheckboxStates.Checked) {
      //          return new Set([...cur, id])
      //       } else {
      //          return new Set([...cur].filter(an_id => an_id !== id))
      //       }
      //    })
      // }
   }, [order_status.new])

   const onLineClick = useCallback(
      (evt, num) => {
         // console.log('doNothing')
         evt.stopPropagation()
         if (showDropdownMenuOnOrderId !== null) {
            prepShowDropDown(null)
         } else {
            updateRecentOrders(num)
            navigate(`/order-detail?id=${num}`, { replace: false })
         }
      },
      [navigate, prepShowDropDown, showDropdownMenuOnOrderId, updateRecentOrders]
   )

   const buildLines = useCallback((status) => {
      let arr, checkboxes

      if (status === order_status.new) {
         arr = curPageUnsyncOrders
         checkboxes = unsyncLinesChecked
      } else {
         arr = curPageSyncOrders
         // checkboxes = syncLinesChecked
      }
      // console.log(arr);
      return arr?.map((an_order) => {
         const a_customer = getCustomerById(an_order.customer_id)
         // console.log(a_customer);
         const devise = a_customer?.tarifs.ces.currency
         // console.log(checkboxes, curOrderType)
         const found_oMe = allUnsyncOrders.find(an_ome_order => an_ome_order.m3_number === an_order.id)
         return (
            <tr key={`line_${an_order.id}`} className={(an_order.id === curOrder) ? styles.selected : ''} onClick={(evt) => { onLineClick(evt, an_order.id) }}>
               {(curOrderType === tabs.unsync) && checkboxes && <td className={styles.checkbox}>
                  <div>
                     {/* <Checkbox small light checked={(an_order.id === curOrder) ? CheckboxStates.Checked : CheckboxStates.Unchecked} onChange={(state) => onChangeChoice(an_order.id, state)} /> */}
                     <Checkbox small light checked={checkboxes.has(an_order.id) ? CheckboxStates.Checked : CheckboxStates.Unchecked} onChange={(state) => onChangeChoice(an_order.id, state, status)} />
                  </div>
               </td>}
               <td className={styles.order_type}>
                  <div>
                     {(an_order.type === type_order.commande) && <FontAwesomeIcon icon={faGem} />}
                     {(an_order.type === type_order.implant) && <FontAwesomeIcon icon={faShelves} />}
                     {(an_order.type === type_order.confie) && <FontAwesomeIcon icon={faHandHoldingBox} />}
                     {(an_order.type === type_order.devis) && <FontAwesomeIcon icon={faFilePen} />}
                     <span>{an_order.type}</span>
                  </div>
               </td>
               <td className={styles.num_order}>
                  <div>
                     <span>{an_order.id}</span>
                     {an_order.mark && <span><FontAwesomeIcon icon={faBookmark} />{an_order.mark}</span>}
                  </div>
               </td>
               {(status !== order_status.new) && <td className={styles.num_ome}>
                  {found_oMe && <>
                     <span>{found_oMe ? found_oMe.id : ""}</span>
                     <div className={styles.green}>   {/* Envoyé vers M3 */}
                        <FontAwesomeIcon icon={faCloud} />
                     </div>
                  </>
                  }
               </td>}
               <td className={styles.name}>
                  <div>
                     <span>{a_customer?.name ?? '???'}</span>
                     <span>{a_customer?.email ?? '???'}</span>
                  </div>
               </td>
               <td className={styles.date}>
                  <div>
                     <span>{an_order.date}</span>
                     {/* {!an_order.readonly && <span>{an_order.time}</span>} */}
                  </div>
               </td>
               <td className={styles.total_price}>{formatCESPrice(calcTotalForOrder(an_order), devise)}</td>
               {(curOrderType === tabs.unsync) && <td className={styles.signature}>
                  <div>
                     {an_order.signature &&
                        <FontAwesomeIcon icon={faSignature} />
                     }
                  </div>
               </td>}
               {(status === order_status.new) &&
                  <td className={styles.sync}>
                     {(an_order.status !== order_status.new) && (an_order.status !== order_status.deleted) && (an_order.status !== order_status.locked4sync) &&
                        <div className={styles.green}>   {/* Envoyé vers M3 */}
                           <FontAwesomeIcon icon={faCloud} />
                        </div>
                     }
                     {an_order.status === order_status.locked4sync &&
                        <div className={styles.orange}>   {/* Marquée pour envoi vers M3, mais pas encore envoyée, peut-être pb réseau temporaire */}
                           <FontAwesomeIcon icon={faCloudXmark} />
                        </div>
                     }
                     {an_order.status === order_status.deleted &&
                        <div className={styles.red}>   {/* Marquée pour suppression mais pas encore envoyée, peut-être pb réseau temporaire */}
                           <FontAwesomeIcon icon={faTrashXmark} />
                        </div>
                     }
                     {/* {an_order.status === order_status.new &&
                     <div className={styles.gray}>
                        <FontAwesomeIcon icon={faCloudSlash} />
                     </div>
                  } */}
                  </td>
               }
               <td className={styles.actions}>
                  <span onClick={(evt) => onShowMenu(evt, an_order.id)}>
                     {/* <FontAwesomeIcon icon={faGear} /> */}
                     {trans('?Détail', true)}
                     <FontAwesomeIcon icon={faAngleRight} />
                     {showDropdownMenuOnOrderId === an_order.id ? dropdownMenu : ''}
                  </span>
               </td>
            </tr>
         )
      })

   }, [allUnsyncOrders, calcTotalForOrder, curOrder, curOrderType, curPageSyncOrders, curPageUnsyncOrders, dropdownMenu, formatCESPrice, getCustomerById, onChangeChoice, onLineClick, onShowMenu, order_status.deleted, order_status.locked4sync, order_status.new, showDropdownMenuOnOrderId, trans, type_order, unsyncLinesChecked])

   /**********************
    * Commun SYNC + UNSYNC
    **********************/

   // SYNCed orders
   useEffect(() => {
      let arr
      const str = searchText?.trim().toLowerCase()
      if (str) {
         arr = allSyncOrders?.filter((an_order) => {
            let found
            const a_customer = getCustomerById(an_order.customer_id)

            if (!a_customer) {
               return false
            } else {
               if (searchMode === search_type.in_orders) {
                  found = String(an_order.id).toLowerCase().includes(str) || String(an_order.customer_id).toLowerCase().includes(str) || an_order.mark?.toLowerCase().includes(str) || a_customer.name?.toLowerCase().includes(str) || a_customer.shortname?.toLowerCase().includes(str)
               } else {
                  found = (an_order.lines.find((a_line) => a_line.sku.toLowerCase().includes(str)) !== undefined)
               }
               return found
            }
         })
      } else if (!filterOME && !filterCDE && !filterCDI && !filterCON) {
         arr = allSyncOrders
      } else {
         arr = allSyncOrders?.filter((an_order) => {
            if (filterOME) {
               const found_oMe = allUnsyncOrders.find(an_ome_order => an_ome_order.m3_number === an_order.id)

               if (found_oMe) {
                  return true
               }
            }
            if (filterCDE && an_order.type === type_order.commande) {
               return true
            }
            if (filterCDI && an_order.type === type_order.implant) {
               return true
            }
            if (filterCON && an_order.type === type_order.confie) {
               return true
            }
            return false
         })
      }
      setFilteredSyncOrders(arr)
   }, [allSyncOrders, allUnsyncOrders, filterCDE, filterCDI, filterCON, filterOME, getCustomerById, searchMode, searchText, type_order])

   // UNSYNCed orders
   useEffect(() => {
      let arr
      const str = searchText?.trim().toLowerCase()
      if (str) {
         arr = allUnsyncOrders?.filter((an_order) => {
            let found
            const a_customer = getCustomerById(an_order.customer_id)

            if (!a_customer) {
               return false
            } else {
               if (searchMode === search_type.in_orders) {
                  found = String(an_order.id).toLowerCase().includes(str) || String(an_order.customer_id).toLowerCase().includes(str) || an_order.mark?.toLowerCase().includes(str) || a_customer.name?.toLowerCase().includes(str) || a_customer.shortname?.toLowerCase().includes(str)
               } else {
                  found = (an_order.lines.find((a_line) => a_line.sku.toLowerCase().includes(str)) !== undefined)
               }
               return found
            }
         })
      } else if (!filterCDE && !filterCDI && !filterCON) {
         arr = allUnsyncOrders
      } else {
         arr = allUnsyncOrders?.filter((an_order) => {
            if (filterCDE && an_order.type === type_order.commande) {
               return true
            }
            if (filterCDI && an_order.type === type_order.implant) {
               return true
            }
            if (filterCON && an_order.type === type_order.confie) {
               return true
            }
            return false
         })
      }
      setFilteredUnsyncOrders(arr)
   }, [allUnsyncOrders, filterCDE, filterCDI, filterCON, getCustomerById, searchMode, searchText, type_order])

   useEffect(() => {
      setUnsyncLines(buildLines(order_status.new))
      setSyncLines(buildLines())
   }, [buildLines, order_status])

   /*******************
    * UNSYNC
    *******************/

   useEffect(() => {
      const start = (curPageUnsync - 1) * listPageLength
      setCurPageUnsyncOrders(filteredUnsyncOrders?.slice(start, start + listPageLength))
      setCountFailedSyncOrders(filteredUnsyncOrders?.reduce((prev, order) => {
         if ((order.status === order_status.locked4sync) || (order.status === order_status.deleted)) {
            return prev + 1
         } else {
            return prev
         }
      }, 0) ?? 0)
   }, [curPageUnsync, filteredUnsyncOrders, listPageLength, order_status.deleted, order_status.locked4sync])

   // useEffect(() => {
   //    // Vérifie que le choix affichage "tous" dans la page est compatible avec le nb de ligne
   //    setListPageLength(cur => {
   //       if ((cur > 100) && ((filteredSyncOrders?.length > 1000) || (filteredUnsyncOrders?.length > 1000))) {
   //          console.log('Liste trop longue pour mode "tous"!')
   //          return 100
   //       } else {
   //          return cur
   //       }
   //    })
   // }, [filteredSyncOrders, filteredUnsyncOrders, setListPageLength])

   useEffect(() => {
      let pageLength = listPageLength

      if (pageLength <= 0) {
         pageLength = 1
         console.error(
            `Erreur ListPageLength ! ${listPageLength} - Modifié temporairement en 1`
         )
      }

      let max = Math.max(1, Math.floor((filteredUnsyncOrders?.length + pageLength - 1) / pageLength)); // Nb maxi de pages
      setNbPagesUnsync(max)
      if (curPageUnsync < 1 || curPageUnsync > max) {
         setCurPageUnsync(1)
      }
   }, [curPageUnsync, filteredUnsyncOrders, listPageLength])

   const onFirstPageChangeUnsync = useCallback((val) => {
      console.log('onFirstPageChangeUnsync', val)
      setFirstPageUnsync(val)
   }, [])

   const onPageChangeUnsync = useCallback((val) => {
      console.log('onPageChangeUnsync', val)
      setCurPageUnsync(val)
   }, [])

   useEffect(() => {
      setPagesButtonsUnsync(
         <PagesButtons from={firstPageUnsync} max={nbPagesUnsync} selected={curPageUnsync} onFirstPageChange={onFirstPageChangeUnsync} onPageChange={onPageChangeUnsync}
         ></PagesButtons>
      )
   }, [curPageUnsync, firstPageUnsync, nbPagesUnsync, onFirstPageChangeUnsync, onPageChangeUnsync])

   /*******************
    * SYNC
    *******************/

   useEffect(() => {
      const start = (curPageSync - 1) * listPageLength
      // console.log(filteredSyncOrders, start, listPageLength);
      setCurPageSyncOrders(filteredSyncOrders?.slice(start, start + listPageLength))
   }, [curPageSync, filteredSyncOrders, listPageLength])

   useEffect(() => {
      let pageLength = listPageLength

      if (pageLength <= 0) {
         pageLength = 1
         console.error(
            `Erreur ListPageLength ! ${listPageLength} - Modifié temporairement en 1`
         )
      }

      let max = Math.max(1, Math.floor((filteredSyncOrders?.length + pageLength - 1) / pageLength)); // Nb maxi de pages
      setNbPagesSync(max)
      // console.log('>>', filteredSyncOrders, max, curPageSync);
      if (curPageSync < 1 || curPageSync > max) {
         setCurPageSync(1)
      }
   }, [curPageSync, filteredSyncOrders, listPageLength])

   useEffect(() => {
      const start = (curPageSync - 1) * listPageLength
      setCurPageSyncOrders(allSyncOrders?.slice(start, start + listPageLength))
   }, [allSyncOrders, curPageSync, listPageLength])

   const onFirstPageChangeSync = useCallback((val) => {
      console.log('onFirstPageChangeSync', val)
      setFirstPageSync(val)
   }, [])

   const onPageChangeSync = useCallback((val) => {
      console.log('onPageChangeSync', val)
      setCurPageSync(val)
   }, [])

   useEffect(() => {
      // console.log(curPageSync, firstPageSync, nbPagesSync);
      setPagesButtonsSync(
         <PagesButtons from={firstPageSync} max={nbPagesSync} selected={curPageSync} onFirstPageChange={onFirstPageChangeSync} onPageChange={onPageChangeSync}
         ></PagesButtons>
      )
   }, [curPageSync, firstPageSync, nbPagesSync, onFirstPageChangeSync, onPageChangeSync])

   /************************
    * FIN DES SYNC ET UNSYNC
    ************************/

   useEffect(() => {
      setDropdownMenu(
         <DropdownMenu noscrollbars right={-20} top={20} onSelect={onSelect}>
            {!selectedOrder?.readonly &&
               <>
                  <li id={menu_items.doEdit}>
                     <FontAwesomeIcon icon={faPenToSquare} />
                     {trans('?Modifier')}
                  </li>
                  <li disabled={curOrder === showDropdownMenuOnOrderId} id={menu_items.setActive}>
                     <FontAwesomeIcon icon={faBadgeCheck} />
                     {trans('?Sélectionner')}
                  </li>
               </>
            }
            {selectedOrder?.readonly &&
               <li id={menu_items.doSee}>
                  <FontAwesomeIcon icon={faEye} />
                  {trans('?Voir')}
               </li>
            }
            <li id={menu_items.sendPdf}>
               <FontAwesomeIcon icon={faFilePdf} />
               {trans('?Envoyer en PDF à...')}
            </li>
            <li id={menu_items.doClone}>
               <FontAwesomeIcon icon={faClone} />
               {trans('?Dupliquer')}
            </li>
            {default_customer && selectedOrder && selectedOrder.customer_id !== default_customer.id && <li id={menu_items.doCloneToDefaultCustomer}>
               <FontAwesomeIcon icon={faClone} />
               <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span>
                     {trans('?Dupliquer')} {lowtrans('?Vers')}
                  </span>
                  <span>
                     {default_customer.name}
                  </span>
               </div>
            </li>}
            {!selectedOrder?.readonly && <li id={menu_items.doDelete}>
               <FontAwesomeIcon icon={faTrashCan} />
               {trans('?Supprimer')}
            </li>}
            {!selectedOrder?.readonly && <li id={menu_items.doSync} disabled={(selectedOrder?.status !== order_status.new) || (selectedOrder && !canSyncOrder(selectedOrder))}>
               <FontAwesomeIcon icon={faCloudArrowUp} />
               {trans('?Synchroniser')}
            </li>}
         </DropdownMenu>
      )
      const left_toolz = (
         <div className={styles_Searchbar.left_tools}>
            <button className={filterCDE ? styles_Searchbar.state_on : styles_Searchbar.state_off} onClick={() => { setFilterCDE(cur => !cur) }}>
               <FontAwesomeIcon icon={faGem} />
               <span>CDE</span>
            </button>
            <button className={filterCDI ? styles_Searchbar.state_on : styles_Searchbar.state_off} onClick={() => { setFilterCDI(cur => !cur) }}>
               <FontAwesomeIcon icon={faShelves} />
               <span>CDI</span>
            </button>
            <button className={filterCON ? styles_Searchbar.state_on : styles_Searchbar.state_off} onClick={() => { setFilterCON(cur => !cur) }}>
               <FontAwesomeIcon icon={faHandHoldingBox} />
               <span>CON</span>
            </button>
            {(curOrderType === tabs.sync) &&
               <button className={filterOME ? styles_Searchbar.state_on : styles_Searchbar.state_off} onClick={() => { setFilterOME(cur => !cur) }}>
                  oMe
               </button>}
         </div>
      )
      setSearchBar(
         <Searchbar onChange={onSearchChange} text={searchText} placeholder={trans((searchMode === search_type.sku) ? '?Chercher un SKU' : '?Chercher une commande')} left_tools={
            left_toolz}>
            <div className={styles_Searchbar.search_tools}>
               <button className={(searchMode === search_type.in_orders) ? styles_Searchbar.red : ''} onClick={() => { setSearchMode(search_type.in_orders) }}>
                  <FontAwesomeIcon icon={faFileLinesSolid} />
               </button>
               <button className={(searchMode === search_type.sku) ? styles_Searchbar.red : ''} onClick={() => { setSearchMode(search_type.sku) }}>
                  <FontAwesomeIcon icon={faGem} />
               </button>
            </div>
         </Searchbar>
      )
   }, [canSyncOrder, curCustomer, curOrder, curOrderType, default_customer, filterCDE, filterCDI, filterCON, filterOME, lowtrans, onSearchChange, onSelect, order_status.new, searchMode, searchText, selectedOrder, showDropdownMenuOnOrderId, trans])

   const onSelectGlobal = useCallback((id) => {
      setShowDropdownMenuGlobal(false)
      if (id === global_menu_items.doSync) {
         setIsConfirmSyncVisible(true)
      }
   }, [])

   useEffect(() => {
      setDropdownMenuGlobal(
         <DropdownMenu noscrollbars right={-20} top={30} onSelect={onSelectGlobal}>
            <li id={global_menu_items.doSync} disabled={unsyncLinesNoneChecked}>
               <FontAwesomeIcon icon={faCloudArrowUp} />
               {trans('?Synchroniser')}
            </li>
         </DropdownMenu>
      );
   }, [onSelectGlobal, trans, unsyncLinesNoneChecked])
   // console.log(selectedOrder);

   useEffect(() => {
      setIsDarkLayerVisible(isMailAddressPickerVisible)
   }, [isMailAddressPickerVisible, setIsDarkLayerVisible])

   function toogleShowDropdownMenuGlobal(evt) {
      evt.stopPropagation();
      closeAllDropDowns();
      setShowDropdownMenuGlobal(!showDropdownMenuGlobal);
   }

   function onGlobalClick(evt) {
      evt.stopPropagation()
      // prepShowDropDown(null)
      closeAllDropDowns()
   }

   function toggleItemsPerPageShowList() {
      setIsItemsPerPageOpen(!isItemsPerPageOpen)
   }

   function onItemsPerPageSelect(id) {
      // console.log('>>>>>>', id)
      setListPageLength(id)
      setIsItemsPerPageOpen(false)
   }

   function onCancelPickMailAddress() {
      setIsMailAddressPickerVisible(false)
   }

   const onValidatePickMailAddress = useCallback(async (arr_emails, message) => {
      console.log(arr_emails)
      setIsMailAddressPickerVisible(false)

      const order = await getOrderById(lastPickedOrderId)
      awPushPDF('CDE', order, arr_emails, message)
   }, [awPushPDF, getOrderById, lastPickedOrderId])

   const pushWaiting = useCallback(() => {
      awPushWaitingOrders()
   }, [awPushWaitingOrders])

   // async function deleteOrder() {
   //    const arr = await db.orders.toArray()
   //    // console.log(arr)
   //    await db.orders.delete(arr[0].id)
   // }

   return (
      <Fragment>
         <Menu page="orders" />
         <div className={styles.orders} onClick={onGlobalClick}>
            {searchBar}
            <div className={styles.content}>
               <div className={styles.header_bg}>
                  <div className={styles.header}>
                     <h1>
                        {trans('?Commande', true)}
                        {' '}
                        ({allSyncOrders?.length})
                        {(curOrderType === tabs.unsync) && (countFailedSyncOrders > 0) && <Button small primary red onClick={pushWaiting}>{trans("?Forcer synchronisation")}</Button>}
                     </h1>
                     <div className={styles.order_types}>
                        <span className={(curOrderType === tabs.sync) ? styles.selected : ''} onClick={() => setCurOrderType(tabs.sync)}>
                           <FontAwesomeIcon icon={faBagShopping} />
                           {trans('?Traitée', true)}
                           {' '}
                           ({filteredSyncOrders?.length})
                           <div className={styles.bot_border} />
                        </span>
                        <span className={(curOrderType === tabs.unsync) ? styles.selected : ''} onClick={() => setCurOrderType(tabs.unsync)}>
                           <FontAwesomeIcon icon={faCloudSlash} />
                           {trans('?En cours oMe')}
                           {' '}
                           ({filteredUnsyncOrders?.length})
                           <div className={styles.bot_border} />
                        </span>
                     </div>
                  </div>
               </div>
               <div className={styles.detail}>
                  <table>
                     <thead>
                        <tr>
                           {(curOrderType === tabs.unsync) && <th>
                              <div>

                                 <Checkbox small light three_states checked={unsyncLinesNoneChecked ? CheckboxStates.Unchecked : unsyncLinesAllChecked ? CheckboxStates.Checked : CheckboxStates.Undefined} onChange={() => onSelAllChange(!unsyncLinesAllChecked ? true : false)} />
                              </div>
                           </th>
                           }
                           {/* <th>
                              <div>
                                 {(curOrderType === tabs.unsync) &&
                                    <Checkbox small light three_states checked={unsyncLinesNoneChecked ? CheckboxStates.Unchecked : unsyncLinesAllChecked ? CheckboxStates.Checked : CheckboxStates.Undefined} onChange={() => onSelAllChange(!unsyncLinesAllChecked ? true : false)} />
                                 }
                                 {(curOrderType === tabs.sync) &&
                                    <Checkbox small light three_states checked={syncLinesNoneChecked ? CheckboxStates.Unchecked : syncLinesAllChecked ? CheckboxStates.Checked : CheckboxStates.Undefined} onChange={() => onSelAllChange(!syncLinesAllChecked ? true : false)} />
                                 }
                              </div>
                           </th> */}
                           <th>{trans('?Type')}</th>
                           <th>{trans('?N° Cde')}</th>
                           {(curOrderType === tabs.sync) && <th className={styles.aligncenter}>oMe</th>}
                           <th>{trans('?Nom client')}</th>
                           <th>{trans('?Date')}</th>
                           <th className={styles.alignright}>{trans('?Prix total')}</th>
                           {(curOrderType === tabs.unsync) && <th><FontAwesomeIcon icon={faSignature} /></th>}
                           {(curOrderType === tabs.unsync) && <th className={styles.aligncenter}>{trans('?Sync')}</th>}
                           {(curOrderType === tabs.unsync) && <th className={styles.action}>
                              <Button secondary onClick={toogleShowDropdownMenuGlobal} disabled={(curOrderType === tabs.unsync && unsyncLinesNoneChecked)}>
                                 {trans('?Action', true)}
                                 {showDropdownMenuGlobal && dropdownMenuGlobal}
                              </Button>
                           </th>}
                        </tr>
                     </thead>
                     <tbody>
                        {(curOrderType === tabs.unsync) && unsyncLines}
                        {(curOrderType !== tabs.unsync) && syncLines}
                     </tbody>
                  </table>
               </div>
               <div className={styles.footer_bg}>
                  <div className={styles.footer}>
                     <span>{trans('?Affichage de')}</span>
                     <DropdownField selectedValue={String(listPageLength)} showAbove height={36} onSelect={onItemsPerPageSelect} isOpen={isItemsPerPageOpen} onToggle={toggleItemsPerPageShowList} >
                        <Fragment key="10">10</Fragment>
                        <Fragment key="20">20</Fragment>
                        <Fragment key="50">50</Fragment>
                        <Fragment key="100">100</Fragment>
                        {/* <Fragment key="999999">{lowtrans("?Toutes")}</Fragment> */}
                     </DropdownField>
                     <span>
                        {(listPageLength < 99999) ? trans('?par page') : ''}
                     </span>
                     <div>
                        {((curOrderType === tabs.unsync) && (listPageLength < 99999)) && pagesButtonsUnsync}
                        {((curOrderType !== tabs.unsync) && (listPageLength < 99999)) && pagesButtonsSync}
                        <span></span>
                        <Button>
                           <FontAwesomeIcon icon={faUpToLine} />
                        </Button>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         {isConfirmDeleteVisible && (
            <Confirm yesno onOK={onConfirmDeleteOK} onCancel={onConfirmDeleteCancel} title={trans("?Suppression")} text={trans("?confirmer_suppression_bdc")} />
         )}
         {isConfirmSyncVisible && (
            <Confirm yesno onOK={onConfirmSyncOK} onCancel={onConfirmSyncCancel} title={trans("?Synchronisation")} text={trans("?confirmer_synchronisation_bdc")} />
         )}
         {isMailAddressPickerVisible && (
            <MailAddressPicker customer={lastPickedOrderCustId} onCancel={onCancelPickMailAddress} onValidate={onValidatePickMailAddress} top={'20%'} minWidth={'30%'} maxWidth={'50%'} />
         )}
         <ToastContainer />
      </Fragment>
   )
}

export default Orders
